<template>
  <div class="flex-col page">
        <div class="flex-col section_3">
          <!-- 表单列表 -->
          <span class="text_6">零部件信息</span>
          <div class="list">
            <div class="list-item flex-row" :key="i" v-for="(item, i) in list">
              <span class="text_5">{{item.name}}</span>
              <span class="text_7">{{item.info}}</span>
            </div>
          </div>
          <span class="text_19">零部件图片</span>
          <img src="../../../assets/img1.png" class="image_5" />
        </div>
      <div class="flex-row group_14">
        <div class="flex-col items-center text-wrapper_1">
          <span>关闭</span>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        { name: '部件标识编码', info: '232323322' },
        { name: '产品代理商', info: '金山小区' },
        { name: '出厂编号', info: 'A区102号楼一单元02梯' },
        { name: '产品名称', info: '客梯' },
        { name: '产品型号', info: '23265655554545551' },
        { name: '产品参数', info: '半月保' },
        { name: '设计寿命', info: '赵四' }
      ]
    }
  }
}
</script>
<style scoped>
@import '../../../assets/common.css';
.list-item_1 {
  padding-bottom: 1.13rem;
  border-bottom: solid 0.063rem rgb(243, 249, 244);
}
.list-item {
  color: rgb(102, 102, 102);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.page {
  padding-top: 0.063rem;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.section_1 {
  padding: 0.94rem 0.81rem 0.88rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
  background-color: rgb(237, 237, 237);
}
.group_1 {
  padding-bottom: 0.94rem;
  overflow-y: auto;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.group_2 {
  height: 46.75rem;
}
.group_14 {
  margin-top: 2.19rem;
  padding: 0 1.25rem;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.69rem;
}
.section_2 {
  padding-left: 1.5rem;
  padding-bottom: 3.5rem;
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  line-height: 1.44rem;
  white-space: nowrap;
  background-image: url(../../../assets/imgbg1_03.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.section_3 {
  margin-top: -1rem;
  padding: 1.94rem 0.88rem 2.69rem 1.25rem;
  background: #fff;
  border-radius: 1rem;
  position: relative;
}
.text-wrapper {
  padding: 1.13rem 0 1.06rem;
  flex: 1 1 10rem;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
  background: rgb(0, 93, 219);
  border-radius: 2rem;
  height: 3.13rem;
}
.text-wrapper_1 {
  margin-left: 0.94rem;
  padding: 1.06rem 0;
  flex: 1 1 10rem;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  background: rgb(0, 93, 219);
  border-radius: 2rem;
  height: 3.13rem;
}
.text_1 {
  margin-top: 1.88rem;
}
.group_3 {
  width: 6.44rem;
}
.text_6 {
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
}
.image_2 {
  width: 5.31rem;
  height: 6.75rem;
}
.image_3 {
  width: 3.25rem;
  height: 1.44rem;
}
.list-item {
  padding: 1.25rem 0 1rem;
  border-bottom: solid 0.063rem rgb(243, 249, 244);
}
.text_5 {
  margin-top: 0.13rem;
  color: rgb(179, 179, 178);
  font-size: 0.75rem;
  line-height: 0.75rem;
  white-space: nowrap;
  width: 5rem;
  display: inline-block;
}
.text_7 {
  margin-left: 1.88rem;
  color: rgb(101, 102, 101);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.list-item:first-of-type {
  border-top: solid 0.063rem rgb(243, 249, 244);
}
.text_19 {
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
  margin-top: 0.8rem;
  margin-bottom: 1.5rem;
}
.image_5 {
  width: 4.81rem;
  height: 4.81rem;
}
</style>

